/* BOTON WHATSAPP */

.whatsapp-button {
    position: fixed;
    bottom: 80px;
    right: 10px;
    background-color: #25D366;
    color: white;
    border: none;
    width: 53px;
    height: 51.5px;
    border-radius: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s;
    z-index: 4;
    padding: 5px 12px;
}

.whatsapp-button:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.4);
}

.whatsapp-icon {
    font-size: 35px;
}

/* SCROLL */

@keyframes slideIn {
    0% {
        transform: translateY(100vh);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slideOut {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(100vh);
    }
}

/* BOTON IR ARRIBA */

.upper.slide-in {
    animation: slideIn 0.5s forwards;
}

.upper.slide-out {
    animation: slideOut 0.5s forwards;
}

.upper {
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 5px 12px;
    background: #2c3e50;
    border: 2px solid white;
    cursor: pointer;
    border-radius: 100%;
    z-index: 2;
    transition: 0.2s ease-out;
}

.upper:hover {
    background: #6788a3;
    transition: 0.5s;
}

/* PROGRRES BAR */

@keyframes progress {
    0% {
        width: 0%;
    }
    
    100% {
        width: 100%;
    }
}

#progress {
    position: fixed;
    width: 0%;
    background: #6788a3;
    height: 5px;
    opacity: 1;
    animation: progress auto linear;
    animation-timeline: scroll(root);
    z-index: 2;
    margin: 0;
    padding: 0;
}

/* NAVBAR */

.navbar-toggler{
    border-radius: 0px;
    border-width: 2px;
    border-color: #6788a3;
}

.navbar {
    position: sticky;
    width: 100%;
    opacity: 0.95;
    top: 0;
    padding: 4px;
    margin: 0;
    z-index: 3;
}

.navbar-nav {
    color: #6788a3;
    font-size: 22px;
    font-weight: bold;
}

.dropdown-menu {
    min-width: max-content;
    background-color: #f8f9fa;
    border-radius: 0px;
    border: none;
    padding: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.dropdown-toggle::after {
    transition: transform 0.3s ease-in-out;
}

.navbar .dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(-180deg);
}

.nav-link:hover {
    background-color: #6788a3;
    transition: 0.7s;
}

.nav-link:focus {
    background-color: #6788a3;
}

/* HOME */

@media only screen and (min-width:0px) and (max-width:768px) {

    .carousel-inner,
    .carousel-item,
    .carousel-item img {
        height: 50vh !important;
        max-height: 50vh !important;
    }

    .logoTexto {
        hr {
            display: none;
        }

        p {
            display: none;
        }
    }
}

.carousel-inner {
    height: 830px;
    /* Fija la altura para evitar cambios */
    opacity: 0.5;
}

.carousel-item {
    height: 100%;
}

.carousel-item img {
    width: 100%;
    height: 830px;
    /* Asegura que todas las imágenes tengan la misma altura */
    object-fit: cover;
    /* Evita distorsiones */
}

.tituloHome {
    margin: 3% 0 1%;
    font-weight: bold;
    color: #616161;
    text-align: center;
}

.linea {
    background-color: #2c3e50;
    height: 7px;
    width: 5%;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
}

.tituloQuienesSomos {
    font-weight: bold;
    color: #fff;
    text-align: center;
}

.textoQuienesSomos {
    color: #c8c8c8;
    font-size: 20px;
    text-align: center;
}

.lineaQuienesSomos {
    margin-left: auto;
    margin-right: auto;
    border-style: solid;
    height: 5px;
    width: 5%;
    background-color: #fff;
    opacity: 1;
}

.botonHome {
    text-transform: uppercase;
    text-decoration: none;
    background-color: #6788a3;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 25px;
    transition: 0.5s ease-in-out;

    &:hover {
        background-color: rgb(61, 79, 97);
        color: #fff;
        transition: 0.5s;
    }
}

.textoHome {
    font-size: 20px;
    margin-left: 10%;
    margin-right: 2%;
}

.galeria {
    display: grid;
    width: 100%;
    margin: auto;
    overflow: hidden;
}

@media (min-width: 1024px) {
    .galeria {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 1023px) and (min-width: 768px) {
    .galeria {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 767px) {
    .galeria {
        grid-template-columns: repeat(1, 1fr);
    }
}

.imagen-container {
    position: relative;
    overflow: hidden;
    display: block;
}

.imagen-container img {
    width: 100%;
    vertical-align: top;
    height: 400px;
    object-fit: cover;
    transition: opacity 0.5s ease-in-out;
}

.imagen-container:hover img {
    opacity: 0.2;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #002952;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    padding: 20px;
    text-align: center;
}

.imagen-container:hover .overlay {
    opacity: 0.7;
}

.overlay h3,
.overlay p {
    color: white;
    /* margin: 5px 0; */
}

.botonWhatsApp {
    text-decoration: none;
    background-color: #25d366;
    color: #1c1e21;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 25px;
    transition: 0.5s ease-in-out;

    &:hover {
        background-color: #1c1e21;
        color: #fff;
        transition: 0.5s;
    }
}

/* SWIPER */

@media only screen and (min-width:576px) and (max-width:699px) {
    .swiper-slide {
        margin: 40px;
    }
}

@media only screen and (min-width:700px) and (max-width:992px) {
    .swiper-slide {
        margin: 70px;
    }
}

.swiper {
    z-index: 1;
    max-width: 100%;
    min-height: 230px;
}

.swiper-wrapper {
    display: flex;
    align-items: center;
}

.swiper-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 350px;

}

.swiper-slide img {
    display: flex;
    max-width: 300px;
    max-height: 140px;
    object-fit: cover;
}

/* SERVICIOS */

.tituloServicios {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 40px;
    color: #dedede;
}

.accordion-button {
    font-weight: bold;
    font-size: 19px;
}

.textoServicios {
    font-size: 18px;
}

/* INGENIERÍA */

.certificados {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
    .certificadosRayos {
        flex-direction: column-reverse;
    }
}

/* TABLEROS */

@media only screen and (min-width: 0px) and (max-width: 768px) {
    .diseñoTableros {
        flex-direction: column-reverse;
    }
}

.col-lg-6 {
    padding: 0px;
}

.imagenTableros {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    display: block;
}


.galeriaTableros {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.galeriaTableros img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

/* CLIENTES */

.logosClientes {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    max-width: 100%;
    max-height: 150px;
}

.logosClientes img {
    display: flex;
    max-width: max-content;
    max-height: 100px;
    transition: 0.5s;
    object-fit: cover;
}

.logosClientes img:hover {
    transform: scale(0.8);
}

/* CONTACTO */

.contact-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    background-color: #2c3e50;
}

.form-wrapper {
    display: flex;
    flex-wrap: wrap;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    max-width: 1200px;
    width: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    padding: 0px;
}

.contact-info {
    background-color: #2c3e50;
    color: white;
    padding: 20px;
    flex: 1;
    width: 50%;
    min-width: 300px;
}

.contact-info h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.contact-info p {
    font-size: 16px;
    margin-bottom: 10px;
}

.map {
    width: 100%;
    height: 470px;
    margin-bottom: 15px;
}

.contact-details {
    display: flex;
    flex-direction: column;
}

.contact-details a {
    color: #6788a3;
    text-decoration: none;
    font-size: 14px;
    margin: 5px 0;
    transition: 0.3s;
}

.contact-details a:hover {
    color: white;
}

.form-container {
    flex: 1;
    padding: 20px;
    width: 50%;
    min-width: 300px;
}

form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input-group {
    margin-bottom: 15px;
}

.input-group label {
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 5px;
    display: block;
}

.input-group input,
.input-group textarea {
    width: 100%;
    padding: 10px;
    border: 2px solid #6788a3;
    border-radius: 5px;
    font-size: 16px;
    transition: 0.3s;
}

.input-group input:focus,
.input-group textarea:focus {
    border-color: #2c3e50;
    outline: none;
    box-shadow: 0 0 5px rgba(44, 62, 80, 0.5);
}

.input-row {
    display: flex;
    gap: 10px;
}

.input-row .input-group {
    flex: 1;
}

.reCaptcha {
    display: flex;
    justify-content: center;
    margin: 15px 0;
}

@media (max-width: 490px) {

    .contact-info,
    .form-container {
        width: 100%;
        min-width: unset;
    }
}